/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { NextSeo } from 'next-seo';
import { NextRouter, withRouter } from 'next/router';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
// import { NextSeo } from 'next-seo';
import { useMediaQuery } from 'libs/use-media-query';
import { CASE_STUDY_VARIANTS, CASE_STUDY_VARIANTS_SP, EASING_VALUE } from 'libs/constants';
import { mapModifiers } from 'libs/component';
import { InView } from 'react-intersection-observer';
import { Container } from 'components/organisms/container';
import { Row } from 'components/organisms/row';
import { Col } from 'components/organisms/col';
import { Button } from 'components/atoms/button';
import { Heading } from 'components/atoms/heading';
import { Card } from 'components/atoms/card';
import { MaskImage } from 'components/atoms/mask-image';
import { Layout } from 'components/templates/layout';
import { Icon } from 'components/atoms/icon';

interface HomePageProps {
  router: NextRouter;
  lang?: string;
}

/* const preloadList = [
  { rel: 'preload', href: '/images/home/service-sp.jpg', type: 'image/jpg', as: 'image', media: '(max-width: 768px)' },
  {
    rel: 'preload',
    href: '/images/home/service-sp@2x.jpg',
    type: 'image/jpg',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (max-width: 768px)',
  },
  { rel: 'preload', href: '/images/home/service.jpg', type: 'image/jpg', as: 'image', media: '(min-width: 769px)' },
  {
    rel: 'preload',
    href: '/images/home/service@2x.jpg',
    type: 'image/jpg',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (min-width: 769px)',
  },
  {
    rel: 'preload',
    href: '/images/home/service-sp.webp',
    type: 'image/webp',
    as: 'image',
    media: '(max-width: 768px)',
  },
  {
    rel: 'preload',
    href: '/images/home/service-sp@2x.webp',
    type: 'image/webp',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (max-width: 768px)',
  },
  { rel: 'preload', href: '/images/home/service.webp', type: 'image/webp', as: 'image', media: '(min-width: 769px)' },
  {
    rel: 'preload',
    href: '/images/home/service@2x.webp',
    type: 'image/webp',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (min-width: 769px)',
  },
  { rel: 'preload', href: '/images/home/product-sp.jpg', type: 'image/jpg', as: 'image', media: '(max-width: 768px)' },
  {
    rel: 'preload',
    href: '/images/home/product-sp@2x.jpg',
    type: 'image/jpg',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (max-width: 768px)',
  },
  { rel: 'preload', href: '/images/home/product.jpg', type: 'image/jpg', as: 'image', media: '(min-width: 769px)' },
  {
    rel: 'preload',
    href: '/images/home/product@2x.jpg',
    type: 'image/jpg',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (min-width: 769px)',
  },
  {
    rel: 'preload',
    href: '/images/home/product-sp.webp',
    type: 'image/webp',
    as: 'image',
    media: '(max-width: 768px)',
  },
  {
    rel: 'preload',
    href: '/images/home/product-sp@2x.webp',
    type: 'image/webp',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (max-width: 768px)',
  },
  { rel: 'preload', href: '/images/home/product.webp', type: 'image/webp', as: 'image', media: '(min-width: 769px)' },
  {
    rel: 'preload',
    href: '/images/home/product@2x.webp',
    type: 'image/jpg',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (min-width: 769px)',
  },
  {
    rel: 'preload',
    href: '/images/home/case-study-sp.jpg',
    type: 'image/jpg',
    as: 'image',
    media: '(max-width: 768px)',
  },
  {
    rel: 'preload',
    href: '/images/home/case-study-sp@2x.jpg',
    type: 'image/jpg',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (max-width: 768px)',
  },
  { rel: 'preload', href: '/images/home/case-study.jpg', type: 'image/jpg', as: 'image', media: '(min-width: 769px)' },
  {
    rel: 'preload',
    href: '/images/home/case-study@2x.jpg',
    type: 'image/jpg',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (min-width: 769px)',
  },
  {
    rel: 'preload',
    href: '/images/home/case-study-sp.webp',
    type: 'image/webp',
    as: 'image',
    media: '(max-width: 768px)',
  },
  {
    rel: 'preload',
    href: '/images/home/case-study-sp@2x.webp',
    type: 'image/webp',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (max-width: 768px)',
  },
  {
    rel: 'preload',
    href: '/images/home/case-study.webp',
    type: 'image/webp',
    as: 'image',
    media: '(min-width: 769px)',
  },
  {
    rel: 'preload',
    href: '/images/home/case-study@2x.webp',
    type: 'image/webp',
    as: 'image',
    media: '(-webkit-min-device-pixel-ratio: 2), (min-width: 769px)',
  },
]; */

const HomePage: React.FC<HomePageProps> = ({ router }) => {
  const isSP = useMediaQuery('(max-width: 768px)');
  const isMobile = () => {
    if (typeof window !== 'undefined' && window.innerWidth <= 768) {
      return true;
    }
    return false;
  };
  const [videoPlayed, setVideoPlayed] = useState(false);
  const { scrollY } = useViewportScroll();
  const [pageScrollY, setPageScrollY] = useState(0);
  const [videoOrientation, setVideoOrientation] = useState('horizontal');
  const [sectionDimensions, setSectionDimensions] = useState({
    hero: 900,
    service: 860,
    product: 980,
    caseStudy: 720,
  });
  const heroSectionRef = useRef(null);
  const serviceSectionRef = useRef(null);
  const productSectionRef = useRef(null);
  const caseStudySectionRef = useRef(null);
  const videoRef = useRef(null);
  const videoSPRef = useRef(null);
  const [servicePictureReveal, setServicePictureReveal] = useState(false);
  const [productPictureReveal, setProductPictureReveal] = useState(false);
  const [caseStudyPictureReveal, setCaseStudyPictureReveal] = useState(false);
  const [videoCanPlay, setVideoCanPlay] = useState(false);
  const [videoSPCanPlay, setVideoSPCanPlay] = useState(false);

  const viewPage = (path: string) => {
    router.push(path, path, { scroll: true });
  };

  useEffect(() => {
    scrollY.onChange(v => setPageScrollY(v));
  }, [scrollY]);

  const playVideo = useCallback(() => {
    const videoEl = videoRef.current as unknown as HTMLVideoElement;
    const videoSPEl = videoSPRef.current as unknown as HTMLVideoElement;
    if (videoEl && videoSPEl) {
      if (isMobile()) {
        if (!videoSPEl.muted) {
          videoSPEl.muted = true;
        }
        videoSPEl.currentTime = 0;
        videoSPEl.play();
      } else {
        if (!videoEl.muted) {
          videoEl.muted = true;
        }
        videoEl.currentTime = 0;
        videoEl.play();
      }
    }
  }, []);

  const handleVideoEnded = useCallback(() => {
    if (!videoPlayed) {
      setVideoPlayed(true);
    }
  }, [videoPlayed]);

  const skipVideo = useCallback(() => {
    const videoEl = videoRef.current as unknown as HTMLVideoElement;
    const videoSPEl = videoSPRef.current as unknown as HTMLVideoElement;
    if (videoEl && videoSPEl) {
      if (isMobile()) {
        videoSPEl.pause();
      } else {
        videoEl.pause();
      }
    }
    handleVideoEnded();
  }, [handleVideoEnded]);

  const replayVideo = useCallback(() => {
    setVideoPlayed(false);
    playVideo();
  }, [playVideo]);

  useEffect(() => {
    // get video
    const videoEl = videoRef.current as unknown as HTMLVideoElement;
    const videoSPEl = videoSPRef.current as unknown as HTMLVideoElement;
    if (videoEl) {
      videoEl.addEventListener('ended', handleVideoEnded);
    }
    if (videoSPEl) {
      videoSPEl.addEventListener('ended', handleVideoEnded);
    }
    return () => {
      if (videoEl) {
        videoEl.removeEventListener('ended', handleVideoEnded);
      }
      if (videoSPEl) {
        videoSPEl.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, [handleVideoEnded]);

  const handleVideoCanPlay = useCallback(() => {
    setVideoCanPlay(true);
  }, []);

  const handleVideoSPCanPlay = useCallback(() => {
    setVideoSPCanPlay(true);
  }, []);

  useEffect(() => {
    const videoEl = videoRef.current as unknown as HTMLVideoElement;
    const videoSPEl = videoSPRef.current as unknown as HTMLVideoElement;
    if (videoEl) {
      videoEl.addEventListener('canplaythrough', handleVideoCanPlay);
    }
    if (videoSPEl) {
      videoSPEl.addEventListener('canplaythrough', handleVideoSPCanPlay);
    }
    return () => {
      if (videoEl) {
        videoEl.removeEventListener('canplaythrough', handleVideoCanPlay);
      }
      if (videoSPEl) {
        videoSPEl.removeEventListener('canplaythrough', handleVideoSPCanPlay);
      }
    };
  }, [handleVideoCanPlay, handleVideoSPCanPlay]);

  useEffect(() => {
    const videoEl = videoRef.current as unknown as HTMLVideoElement;

    const checkOrientation = () => {
      // get video ref
      if (videoEl) {
        const videoAspectRatio = videoEl.videoWidth / videoEl.videoHeight;
        const viewportAspectRatio = window.innerWidth / window.innerHeight;
        // console.log('video', videoAspectRatio, 'viewport', viewportAspectRatio, videoAspectRatio >= viewportAspectRatio);
        if (videoAspectRatio >= viewportAspectRatio) {
          setVideoOrientation('vertical');
        } else {
          setVideoOrientation('horizontal');
        }
      }
    };

    const detectSectionDimension = () => {
      const heroSectionEl = heroSectionRef.current as unknown as HTMLDivElement;
      const serviceSectionEl = serviceSectionRef.current as unknown as HTMLDivElement;
      const productSectionEl = productSectionRef.current as unknown as HTMLDivElement;
      const caseStudySectionEl = caseStudySectionRef.current as unknown as HTMLDivElement;

      if (heroSectionEl) {
        const rect = heroSectionEl.getBoundingClientRect();
        sectionDimensions.hero = rect.height;
      }
      if (serviceSectionEl) {
        const rect = serviceSectionEl.getBoundingClientRect();
        sectionDimensions.service = rect.height;
      }
      if (productSectionEl) {
        const rect = productSectionEl.getBoundingClientRect();
        sectionDimensions.product = rect.height;
      }
      if (caseStudySectionEl) {
        const rect = caseStudySectionEl.getBoundingClientRect();
        sectionDimensions.caseStudy = rect.height;
      }
      setSectionDimensions(sectionDimensions);
    };

    const handleResize = () => {
      checkOrientation();
      detectSectionDimension();
    };

    detectSectionDimension();

    if (videoEl) {
      videoEl.addEventListener('play', checkOrientation);
    }

    if (window) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (videoEl) {
        videoEl.removeEventListener('play', checkOrientation);
      }

      if (window) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [sectionDimensions]);

  // -- ANIMATION
  const heroParallaxScrollRange = [0, sectionDimensions.hero];
  const heroParallax = {
    background: useTransform(scrollY, [0, sectionDimensions.hero], isSP ? [0, 0] : [0, 0]),
    title: useTransform(scrollY, [0, sectionDimensions.hero], isSP ? [0, 0] : [0, -10]),
    subtitle: useTransform(scrollY, [0, sectionDimensions.hero], isSP ? [0, 0] : [0, -50]),
    cta: useTransform(scrollY, [0, sectionDimensions.hero], isSP ? [0, 0] : [0, -20]),
  };
  const heroVariants = {
    root: {
      enter: {
        transition: {
          staggerChildren: 0.25,
          delayChildren: 0.25,
        },
      },
      exit: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
    },
    video: {
      enter: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
          duration: 0.5,
          delay: 0.25,
        },
      },
      exit: {
        opacity: 0,
        scale: 1.2,
        y: -20,
      },
    },
    content: {
      enter: {
        transition: {
          staggerChildren: 0.25,
          delay: 0.25,
        },
      },
      exit: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
    },
    background: {
      enter: {
        opacity: 1,
        scale: 1,
        transition: {
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        scale: 1.1,
      },
    },
    text: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
    scroll: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          deplay: 1,
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
  };

  const serviceParallaxScrollRange = [
    heroParallaxScrollRange[1],
    heroParallaxScrollRange[1] + sectionDimensions.service,
  ];
  const serviceParallax = {
    heading: useTransform(scrollY, serviceParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
    content: useTransform(scrollY, serviceParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
    image: useTransform(scrollY, serviceParallaxScrollRange, isSP ? [0, 0] : [0, 300]),
    imageText: useTransform(scrollY, serviceParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const serviceVariants: any = {
    root: {
      enter: {
        transition: {
          // staggerChildren: 0.25,
          // delayChildren: 0.25,
        },
      },
      exit: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
    },
    graphic: {
      enter: {
        opacity: 1.5,
        x: isSP ? 20 : 300,
        y: 0,
        transition: {
          duration: 1,
          delay: 2.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 50,
        x: isSP ? 20 : 300,
      },
    },
    image: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
        },
      },
      exit: {
        opacity: 0,
        y: 0,
      },
    },
    heading: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          // duration: 0.5,
          delay: isSP ? 0.5 : 1.5,
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
    imageText: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          // duration: 1,
          delay: isSP ? 0.5 : 1.5,
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
    content: {
      enter: {
        transition: {
          // staggerChildren: 0.25,
          // delayChildren: 0.5,
          // delay: 1,
        },
      },
      exit: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
    },
    text: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 1.5,
          ease: EASING_VALUE,
          delay: isSP ? 0.5 : 1.5,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
    cta: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 1.5,
          ease: EASING_VALUE,
          delay: isSP ? 0.75 : 1.75,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
  };

  const productParallaxScrollRange = [
    serviceParallaxScrollRange[1],
    serviceParallaxScrollRange[1] + sectionDimensions.product,
  ];
  const productParallax = {
    heading: useTransform(scrollY, productParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
    content: useTransform(scrollY, productParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
    image: useTransform(scrollY, productParallaxScrollRange, isSP ? [0, 0] : [0, 200]),
    imageText: useTransform(scrollY, productParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
    feature: useTransform(scrollY, productParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const productVariants: any = {
    root: {
      enter: {
        transition: {
          // staggerChildren: 0.25,
          // delayChildren: 0.25,
        },
      },
      exit: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
    },
    graphic: {
      enter: {
        opacity: 1,
        x: 292,
        y: 0,
        transition: {
          duration: 1.5,
          delay: 0.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 100,
        x: 292,
      },
    },
    heading: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          delay: isSP ? 0.5 : 1.5,
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
    image: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
        },
      },
      exit: {
        opacity: 0,
        y: 0,
      },
    },
    imageText: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          delay: isSP ? 0.5 : 1.5,
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
    content: {
      enter: {
        transition: {
          // staggerChildren: 0.1,
          // delayChildren: 0.5,
          // delay: 0.5,
        },
      },
      exit: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
    },
    text: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          delay: isSP ? 0.5 : 1.5,
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
    cta: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          delay: isSP ? 0.75 : 1.25,
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
    featureBlock: {
      enter: {
        transition: {
          staggerChildren: 0.25,
        },
      },
      exit: {},
    },
    featureBlockTitle: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          delay: 2,
          duration: 1.5,
          ease: EASING_VALUE,
        },
      },
      exit: {
        opacity: 0,
        y: 10,
      },
    },
    feature: {
      enter: {
        opacity: 1,
        transition: {
          delay: 2,
          staggerChildren: 0.5,
          delayChildren: 0.25,
        },
      },
      exit: {
        opacity: 0,
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
    },
    featureItem: {
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 2,
          ease: EASING_VALUE,
          // type: 'spring',
          // stiffness: 400,
          // damping: 40,
        },
      },
      exit: {
        opacity: 0,
        y: 50,
      },
    },
  };

  const caseStudyParallaxScrollRange = [
    productParallaxScrollRange[0],
    productParallaxScrollRange[1] + sectionDimensions.caseStudy,
  ];
  const caseStudyParallax = {
    heading: useTransform(scrollY, caseStudyParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
    content: useTransform(scrollY, caseStudyParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
    image: useTransform(scrollY, caseStudyParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
    imageText: useTransform(scrollY, caseStudyParallaxScrollRange, isSP ? [0, 0] : [0, 0]),
  };
  const caseStudyVariants = isSP ? CASE_STUDY_VARIANTS_SP : CASE_STUDY_VARIANTS;

  return (
    <Layout pageId="/">
      <div className="p-home">
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            zIndex: 9999,
            color: 'red',
            display: 'none',
          }}
        >
          {pageScrollY} {JSON.stringify(sectionDimensions)}
          <pre>
            video: {JSON.stringify(videoCanPlay)}, video sp: {JSON.stringify(videoSPCanPlay)}
          </pre>
        </div>
        {/* <NextSeo additionalLinkTags={preloadList} /> */}
        {/* <!-- HERO --> */}
        <section ref={heroSectionRef} className="o-section-hero">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <motion.div
                ref={ref}
                className="o-section-hero__wrapper"
                initial={false}
                animate={inView ? 'enter' : 'exit'}
                variants={heroVariants.root}
                onAnimationComplete={() => {
                  playVideo();
                }}
              >
                <motion.div
                  className={mapModifiers('o-section-hero__video', videoOrientation, videoPlayed && 'played')}
                  variants={heroVariants.video}
                  style={{
                    y: heroParallax.background,
                  }}
                >
                  {!(videoCanPlay || videoSPCanPlay) && (
                    <motion.div
                      className="o-section-hero__video-loading"
                      initial={{
                        rotate: 0,
                        x: '-50%',
                        y: '-50%',
                      }}
                      animate={{
                        rotate: 360,
                        x: '-50%',
                        y: '-50%',
                      }}
                      exit={{
                        rotate: 0,
                        x: '-50%',
                        y: '-50%',
                      }}
                      transition={{
                        repeat: Infinity,
                        duration: 1,
                        ease: 'easeInOut',
                      }}
                    >
                      <svg width="120px" height="120px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle
                          cx="50"
                          cy="50"
                          fill="none"
                          stroke="#1d2e77"
                          strokeWidth="2"
                          r="24"
                          strokeDasharray="113.09733552923255 39.69911184307752"
                        ></circle>
                      </svg>
                    </motion.div>
                  )}
                  <video className="o-section-hero__video-sp" ref={videoSPRef} muted={true} playsInline={true}>
                    <source type="video/webm" src="/videos/home-loop-sp.webm" />
                    <source type="video/mp4" src="/videos/home-loop-sp.mp4" />
                  </video>
                  <video className="o-section-hero__video-pc" ref={videoRef} muted={true} playsInline={true}>
                    <source type="video/webm" src="/videos/home-loop.webm" />
                    <source type="video/mp4" src="/videos/home-loop.mp4" />
                  </video>
                </motion.div>
                <motion.div
                  className="o-section-hero__content"
                  initial={false}
                  animate={videoPlayed ? 'enter' : 'exit'}
                  variants={heroVariants.content}
                >
                  <motion.div className="o-section-hero__content-background" variants={heroVariants.background}>
                    <div className={mapModifiers('o-section-hero__content-graphic', 'pc')}>
                      <svg width="1440" height="1062" viewBox="0 0 1440 1062">
                        <g id="Group_25424" data-name="Group 25424" transform="translate(-830 -450.295)">
                          <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(830 450)">
                            <g
                              id="Group_25728"
                              data-name="Group 25728"
                              transform="matrix(-0.978, -0.208, 0.208, -0.978, 2039.329, 2015.288)"
                            >
                              <path
                                id="Path_22313"
                                data-name="Path 22313"
                                d="M510.341,0A5602.038,5602.038,0,0,0,0,353.545L46.686,166.43S344.939,37.4,510.341,0Z"
                                transform="translate(2109.988 8.12)"
                                fill="#df1f1c"
                              />
                              <path
                                id="Path_22314"
                                data-name="Path 22314"
                                d="M133.222,160.571S41.328,138.66,0,135L54.388,0Z"
                                transform="translate(1797.158 416.181)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22315"
                                data-name="Path 22315"
                                d="M172.961,138.951,207.634,0S100.575,43.685,0,93.07Z"
                                transform="translate(1851.552 321.61)"
                                fill="#001e42"
                              />
                              <path
                                id="Path_22316"
                                data-name="Path 22316"
                                d="M254.679,0S182.393,127.841,145.762,200.565L0,196.3Z"
                                transform="translate(1646.336 568.694)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22317"
                                data-name="Path 22317"
                                d="M308.568,129.616,242.366,0,0,220.187Z"
                                transform="translate(1542.985 291.914)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22318"
                                data-name="Path 22318"
                                d="M295.3,21.357S186.413,6.79,100,0L0,70.9Z"
                                transform="translate(1797.158 221.009)"
                                fill="#df1f1c"
                              />
                              <path
                                id="Path_22319"
                                data-name="Path 22319"
                                d="M125.234,0,0,223.674S129.878,83.691,197.006,30.367Z"
                                transform="translate(1461.683 734.786)"
                                fill="#df1f1c"
                              />
                              <path
                                id="Path_22320"
                                data-name="Path 22320"
                                d="M481.386,183.2,552.327,0S223.837,172.321,0,331.762Z"
                                transform="translate(1054.204 245.393)"
                                fill="#001976"
                              />
                              <path
                                id="Path_22321"
                                data-name="Path 22321"
                                d="M380.479,0,0,126.271l288.065,39.512Z"
                                transform="translate(1109.244 686.371)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22322"
                                data-name="Path 22322"
                                d="M508.826,0,366.647,252.931,0,131.8Z"
                                transform="translate(925.041 898.187)"
                                fill="#8ea7cd"
                              />
                              <path
                                id="Path_22323"
                                data-name="Path 22323"
                                d="M220.728,0A2330.071,2330.071,0,0,0,0,171.225L508.818,39.512Z"
                                transform="translate(853.566 622.155)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22324"
                                data-name="Path 22324"
                                d="M218.276,0,0,180.341l584.993-59.049Z"
                                transform="translate(632.543 793.37)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22325"
                                data-name="Path 22325"
                                d="M438.694,0S316.254,147.067,279.966,203.587L0,44.337Z"
                                transform="translate(719.84 1326.644)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22326"
                                data-name="Path 22326"
                                d="M302.133,162.926,185.179,0,0,357.908S164.2,239.788,302.133,162.926Z"
                                transform="translate(751.768 1427.142)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22327"
                                data-name="Path 22327"
                                d="M180.581,0,0,164.977,164.267,402.646Z"
                                transform="translate(542.251 1316.274)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22328"
                                data-name="Path 22328"
                                d="M335.626,93.846,174.793,0S52.184,112.067,0,165.854l189.175,63.527Z"
                                transform="translate(464.729 973.699)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22329"
                                data-name="Path 22329"
                                d="M407.393,0,0,117.693,440.279,287.037Z"
                                transform="translate(137.928 1485.238)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22330"
                                data-name="Path 22330"
                                d="M409.68,181.484,243.943,0,0,291.01Z"
                                transform="translate(0 1277.266)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22331"
                                data-name="Path 22331"
                                d="M0,0,124.178,347.523S325.653,89.609,388.017,28.979Z"
                                transform="translate(514.815 1762.994)"
                                fill="#8ea7cd"
                              />
                              <path
                                id="Path_22332"
                                data-name="Path 22332"
                                d="M19.715,0,0,219.079,250.184,383.47Z"
                                transform="translate(79.744 1546.134)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22333"
                                data-name="Path 22333"
                                d="M413.89,0,0,87.653l233.3,77.306Z"
                                transform="translate(239.314 1195.988)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3085"
                                data-name="Ellipse 3085"
                                cx="164.997"
                                cy="164.997"
                                rx="164.997"
                                ry="164.997"
                                transform="translate(2017.241 925.307)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3088"
                                data-name="Ellipse 3088"
                                cx="167.999"
                                cy="167.999"
                                rx="167.999"
                                ry="167.999"
                                transform="translate(325.876 1259.142)"
                                fill="#001976"
                              />
                            </g>
                            <g
                              id="Group_25729"
                              data-name="Group 25729"
                              transform="translate(430.309 415.276) rotate(-75)"
                            >
                              <path
                                id="Path_22315-2"
                                data-name="Path 22315"
                                d="M63.856,51.3,76.658,0S37.132,16.128,0,34.361Z"
                                transform="translate(686.659 75.505)"
                                fill="#001e42"
                              />
                              <path
                                id="Path_22316-2"
                                data-name="Path 22316"
                                d="M94.026,0S67.338,47.2,53.815,74.048L0,72.474Z"
                                transform="translate(710.733 186.386)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22317-2"
                                data-name="Path 22317"
                                d="M113.922,47.854,89.48,0,0,81.292Z"
                                transform="translate(589.266 95.918)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22319-2"
                                data-name="Path 22319"
                                d="M46.236,0,0,82.579S47.95,30.9,72.734,11.211Z"
                                transform="translate(633.741 260.614)"
                                fill="#df1f1c"
                              />
                              <path
                                id="Path_22320-2"
                                data-name="Path 22320"
                                d="M175.7,53.152,201.592,0S81.7,49.994,0,96.251Z"
                                transform="translate(436.715 67.343)"
                                fill="#001976"
                              />
                              <path
                                id="Path_22321-2"
                                data-name="Path 22321"
                                d="M140.471,0,0,46.618,106.352,61.206Z"
                                transform="translate(500.615 215.443)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22322-2"
                                data-name="Path 22322"
                                d="M187.856,0,135.364,93.381,0,48.661Z"
                                transform="translate(438.305 140.306) rotate(42)"
                                fill="#8ea7cd"
                              />
                              <path
                                id="Path_22323-2"
                                data-name="Path 22323"
                                d="M81.492,0A860.251,860.251,0,0,0,0,63.215L187.853,14.588Z"
                                transform="translate(264.795 97.635)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22324-2"
                                data-name="Path 22324"
                                d="M80.586,0,0,66.581l215.976-21.8Z"
                                transform="translate(209.174 155.248)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22325-2"
                                data-name="Path 22325"
                                d="M161.964,0s-45.2,54.3-58.6,75.163L0,16.369Z"
                                transform="translate(356.849 451.829)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22326-2"
                                data-name="Path 22326"
                                d="M111.546,60.151,68.367,0,0,132.138S60.622,88.529,111.546,60.151Z"
                                transform="translate(368.637 488.932)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22327-2"
                                data-name="Path 22327"
                                d="M66.669,0,0,60.909l60.647,87.746Z"
                                transform="translate(197.337 345.765)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22328-2"
                                data-name="Path 22328"
                                d="M123.911,34.648,64.533,0S19.266,41.374,0,61.233L69.842,84.687Z"
                                transform="translate(186.351 267.948)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22329-2"
                                data-name="Path 22329"
                                d="M150.407,0,0,43.452l162.549,62.521Z"
                                transform="translate(142.01 510.381)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22330-2"
                                data-name="Path 22330"
                                d="M113.369,50.221,67.505,0,0,80.53Z"
                                transform="translate(-49.169 362.45)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22331-2"
                                data-name="Path 22331"
                                d="M0,0,45.846,128.3s74.384-95.22,97.408-117.6Z"
                                transform="translate(79.291 75.848)"
                                fill="#8ea7cd"
                              />
                              <path
                                id="Path_22332-2"
                                data-name="Path 22332"
                                d="M7.279,0,0,80.883l92.367,60.692Z"
                                transform="translate(120.529 532.863)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22333-2"
                                data-name="Path 22333"
                                d="M152.806,0,0,32.361,86.132,60.9Z"
                                transform="translate(29.713 255.821)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3085-2"
                                data-name="Ellipse 3085"
                                cx="60.916"
                                cy="60.916"
                                rx="60.916"
                                ry="60.916"
                                transform="translate(356.344 192.908)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3086"
                                data-name="Ellipse 3086"
                                cx="83.806"
                                cy="83.806"
                                rx="83.806"
                                ry="83.806"
                                transform="translate(-35.468 22.343)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3088-2"
                                data-name="Ellipse 3088"
                                cx="62.024"
                                cy="62.024"
                                rx="62.024"
                                ry="62.024"
                                transform="translate(256.324 361.742)"
                                fill="#001976"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className={mapModifiers('o-section-hero__content-graphic', 'sp')}>
                      <svg width="375" height="480" viewBox="0 0 375 480">
                        <g id="Group_25424" data-name="Group 25424" transform="translate(-830 -612.295)">
                          <g id="Mask_Group_14" data-name="Mask Group 14" transform="translate(830 612.295)">
                            <g
                              id="Group_25728"
                              data-name="Group 25728"
                              transform="matrix(-0.978, -0.208, 0.208, -0.978, 1502.017, 1730.051)"
                            >
                              <path
                                id="Path_22313"
                                data-name="Path 22313"
                                d="M510.341,0A5602.038,5602.038,0,0,0,0,353.545L46.686,166.43S344.939,37.4,510.341,0Z"
                                transform="translate(2109.988 0)"
                                fill="#df1f1c"
                              />
                              <path
                                id="Path_22314"
                                data-name="Path 22314"
                                d="M133.222,160.571S41.328,138.66,0,135L54.388,0Z"
                                transform="translate(1797.158 408.061)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22315"
                                data-name="Path 22315"
                                d="M172.961,138.951,207.634,0S100.575,43.685,0,93.07Z"
                                transform="translate(1851.552 313.49)"
                                fill="#001e42"
                              />
                              <path
                                id="Path_22316"
                                data-name="Path 22316"
                                d="M254.679,0S182.393,127.841,145.762,200.565L0,196.3Z"
                                transform="translate(1646.336 560.574)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22317"
                                data-name="Path 22317"
                                d="M308.568,129.616,242.366,0,0,220.187Z"
                                transform="translate(1542.985 283.795)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22318"
                                data-name="Path 22318"
                                d="M295.3,21.357S186.413,6.79,100,0L0,70.9Z"
                                transform="translate(1797.158 212.889)"
                                fill="#df1f1c"
                              />
                              <path
                                id="Path_22319"
                                data-name="Path 22319"
                                d="M125.234,0,0,223.674S129.878,83.691,197.006,30.367Z"
                                transform="translate(1461.683 726.666)"
                                fill="#df1f1c"
                              />
                              <path
                                id="Path_22320"
                                data-name="Path 22320"
                                d="M481.386,183.2,552.327,0S223.837,172.321,0,331.762Z"
                                transform="translate(1054.204 237.273)"
                                fill="#001976"
                              />
                              <path
                                id="Path_22321"
                                data-name="Path 22321"
                                d="M380.479,0,0,126.271l288.065,39.512Z"
                                transform="translate(1109.244 678.252)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22322"
                                data-name="Path 22322"
                                d="M508.826,0,366.647,252.931,0,131.8Z"
                                transform="translate(925.041 890.067)"
                                fill="#8ea7cd"
                              />
                              <path
                                id="Path_22323"
                                data-name="Path 22323"
                                d="M220.728,0A2330.071,2330.071,0,0,0,0,171.225L508.818,39.512Z"
                                transform="translate(853.566 614.036)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22324"
                                data-name="Path 22324"
                                d="M218.276,0,0,180.341l584.993-59.049Z"
                                transform="translate(632.543 785.25)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22325"
                                data-name="Path 22325"
                                d="M438.694,0S316.254,147.067,279.966,203.587L0,44.337Z"
                                transform="translate(719.84 1318.524)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22326"
                                data-name="Path 22326"
                                d="M302.133,162.926,185.179,0,0,357.908S164.2,239.788,302.133,162.926Z"
                                transform="translate(751.768 1419.022)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22327"
                                data-name="Path 22327"
                                d="M180.581,0,0,164.977,164.267,402.646Z"
                                transform="translate(542.251 1308.155)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22328"
                                data-name="Path 22328"
                                d="M335.626,93.846,174.793,0S52.184,112.067,0,165.854l189.175,63.527Z"
                                transform="translate(464.729 965.579)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22329"
                                data-name="Path 22329"
                                d="M407.393,0,0,117.693,440.279,287.037Z"
                                transform="translate(137.928 1477.118)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22330"
                                data-name="Path 22330"
                                d="M409.68,181.484,243.943,0,0,291.01Z"
                                transform="translate(0 1269.146)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22331"
                                data-name="Path 22331"
                                d="M0,0,124.178,347.523S325.653,89.609,388.017,28.979Z"
                                transform="translate(514.815 1754.875)"
                                fill="#8ea7cd"
                              />
                              <path
                                id="Path_22332"
                                data-name="Path 22332"
                                d="M19.715,0,0,219.079,250.184,383.47Z"
                                transform="translate(79.744 1538.014)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22333"
                                data-name="Path 22333"
                                d="M413.89,0,0,87.653l233.3,77.306Z"
                                transform="translate(239.314 1187.868)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3085"
                                data-name="Ellipse 3085"
                                cx="164.997"
                                cy="164.997"
                                rx="164.997"
                                ry="164.997"
                                transform="translate(1816.602 1031.398)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3088"
                                data-name="Ellipse 3088"
                                cx="167.999"
                                cy="167.999"
                                rx="167.999"
                                ry="167.999"
                                transform="translate(325.876 1251.023)"
                                fill="#001976"
                              />
                            </g>
                            <g
                              id="Group_25729"
                              data-name="Group 25729"
                              transform="translate(-39.835 571.257) rotate(-75)"
                            >
                              <path
                                id="Path_22315-2"
                                data-name="Path 22315"
                                d="M63.856,51.3,76.658,0S37.132,16.128,0,34.361Z"
                                transform="translate(735.828 53.162)"
                                fill="#001e42"
                              />
                              <path
                                id="Path_22316-2"
                                data-name="Path 22316"
                                d="M94.026,0S67.338,47.2,53.815,74.048L0,72.474Z"
                                transform="translate(759.902 164.043)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22317-2"
                                data-name="Path 22317"
                                d="M113.922,47.854,89.48,0,0,81.292Z"
                                transform="translate(638.434 73.575)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22319-2"
                                data-name="Path 22319"
                                d="M46.236,0,0,82.579S47.95,30.9,72.734,11.211Z"
                                transform="translate(682.909 238.271)"
                                fill="#df1f1c"
                              />
                              <path
                                id="Path_22320-2"
                                data-name="Path 22320"
                                d="M175.7,53.152,201.592,0S81.7,49.994,0,96.251Z"
                                transform="translate(485.884 45)"
                                fill="#001976"
                              />
                              <path
                                id="Path_22321-2"
                                data-name="Path 22321"
                                d="M140.471,0,0,46.618,106.352,61.206Z"
                                transform="translate(549.784 193.1)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22322-2"
                                data-name="Path 22322"
                                d="M187.856,0,135.364,93.381,0,48.661Z"
                                transform="translate(487.474 117.963) rotate(42)"
                                fill="#8ea7cd"
                              />
                              <path
                                id="Path_22323-2"
                                data-name="Path 22323"
                                d="M81.492,0A860.251,860.251,0,0,0,0,63.215L187.853,14.588Z"
                                transform="translate(313.964 75.292)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22324-2"
                                data-name="Path 22324"
                                d="M80.586,0,0,66.581l215.976-21.8Z"
                                transform="translate(258.343 132.905)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22325-2"
                                data-name="Path 22325"
                                d="M161.964,0s-45.2,54.3-58.6,75.163L0,16.369Z"
                                transform="translate(406.018 429.486)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22326-2"
                                data-name="Path 22326"
                                d="M111.546,60.151,68.367,0,0,132.138S60.622,88.529,111.546,60.151Z"
                                transform="translate(417.806 466.589)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22327-2"
                                data-name="Path 22327"
                                d="M66.669,0,0,60.909l60.647,87.746Z"
                                transform="translate(246.506 323.422)"
                                fill="#fca518"
                              />
                              <path
                                id="Path_22328-2"
                                data-name="Path 22328"
                                d="M123.911,34.648,64.533,0S19.266,41.374,0,61.233L69.842,84.687Z"
                                transform="translate(235.52 245.605)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22329-2"
                                data-name="Path 22329"
                                d="M150.407,0,0,43.452l162.549,62.521Z"
                                transform="translate(191.179 488.038)"
                                fill="#cbdae9"
                                opacity="0.6"
                              />
                              <path
                                id="Path_22330-2"
                                data-name="Path 22330"
                                d="M113.369,50.221,67.505,0,0,80.53Z"
                                transform="translate(0 340.108)"
                                fill="#ffe900"
                              />
                              <path
                                id="Path_22331-2"
                                data-name="Path 22331"
                                d="M0,0,45.846,128.3s74.384-95.22,97.408-117.6Z"
                                transform="translate(128.46 53.505)"
                                fill="#8ea7cd"
                              />
                              <path
                                id="Path_22332-2"
                                data-name="Path 22332"
                                d="M7.279,0,0,80.883l92.367,60.692Z"
                                transform="translate(169.698 510.521)"
                                fill="#0092e5"
                              />
                              <path
                                id="Path_22333-2"
                                data-name="Path 22333"
                                d="M152.806,0,0,32.361,86.132,60.9Z"
                                transform="translate(78.882 233.478)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3085-2"
                                data-name="Ellipse 3085"
                                cx="60.916"
                                cy="60.916"
                                rx="60.916"
                                ry="60.916"
                                transform="translate(405.513 170.565)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3086"
                                data-name="Ellipse 3086"
                                cx="83.806"
                                cy="83.806"
                                rx="83.806"
                                ry="83.806"
                                transform="translate(13.701)"
                                fill="#df1f1c"
                              />
                              <ellipse
                                id="Ellipse_3088-2"
                                data-name="Ellipse 3088"
                                cx="62.024"
                                cy="62.024"
                                rx="62.024"
                                ry="62.024"
                                transform="translate(305.493 339.399)"
                                fill="#001976"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="o-section-hero__content-bg"></div>
                  </motion.div>
                  <div className="o-section-hero__content-wrapper">
                    <Container>
                      <Row>
                        <Col className="o-section-hero__col" width={8} widthSP={4}>
                          <div className="o-section-hero__text">
                            <motion.div
                              className="o-section-hero__text-top"
                              variants={heroVariants.text}
                              style={{
                                y: heroParallax.title,
                              }}
                            >
                              <h1 className="o-section-hero__text-title">
                                Create Next Digital Payment and Settlement Services
                              </h1>
                            </motion.div>
                            <div className="o-section-hero__text-bottom">
                              <motion.p
                                className="o-section-hero__text-subtitle"
                                variants={heroVariants.text}
                                style={{
                                  y: heroParallax.subtitle,
                                }}
                              >
                                With our abundant experiences in financial IT infrastructure,
                                <br />
                                v-PaSSer assists you in achieving your goals with customization-based system and
                                extraordinary support.
                              </motion.p>
                              <motion.div
                                className="o-section-hero__text-action"
                                variants={heroVariants.text}
                                style={{
                                  y: heroParallax.cta,
                                }}
                              >
                                <Button onClick={() => viewPage('/about')}>About v-PaSSer</Button>
                              </motion.div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </motion.div>
                <button
                  type="button"
                  className={mapModifiers('o-section-hero__content-replay', videoPlayed && 'show')}
                  onClick={() => replayVideo()}
                  aria-label="Replay Video"
                >
                  <Icon name="replay" color="navy" />
                </button>
                <button
                  type="button"
                  className={mapModifiers('o-section-hero__content-skip', !videoPlayed && 'show')}
                  onClick={() => skipVideo()}
                  aria-label="Skip Video"
                >
                  <Icon name="skip" color="navy" />
                </button>
              </motion.div>
            )}
          </InView>
          <motion.div
            className="o-section-hero__scroll"
            initial={false}
            animate={
              videoPlayed
                ? {
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.4,
                      type: 'spring',
                      stiffness: 400,
                      damping: 40,
                      delay: 2,
                    },
                  }
                : { opacity: 0, y: 100 }
            }
          >
            <span className="o-section-hero__scroll-text">Scroll Down</span>
            <div className="o-section-hero__scroll-line">
              <motion.span
                className="o-section-hero__scroll-indicator"
                initial={{
                  y: 0,
                  x: -1.5,
                }}
                animate={{
                  y: 40,
                  x: -1.5,
                }}
                exit={{
                  y: 0,
                  x: -1.5,
                }}
                transition={{
                  type: 'spring',
                  stiffness: 30,
                  repeat: Infinity,
                  repeatDelay: 0.5,
                }}
              >
                <i className="o-section-hero__scroll-indicator-bg"></i>
              </motion.span>
            </div>
          </motion.div>
        </section>
        {/* <!-- /HERO --> */}

        {/* <!-- SERVICES --> */}
        <InView threshold={0.1} triggerOnce>
          {({ inView, ref }) => (
            <section ref={serviceSectionRef} className="o-section-service">
              <motion.div
                ref={ref}
                className="o-section-service__wrapper"
                initial={false}
                animate={inView ? 'enter' : 'exit'}
                variants={serviceVariants.root}
              >
                <motion.div className="o-section-service__graphic" variants={serviceVariants.graphic}>
                  <svg data-name="Component 6 – 6" width="1004.643" height="1173.149" viewBox="0 0 1004.643 1173.149">
                    <g data-name="Group 25887" transform="translate(-8.856 920.6) rotate(-75)">
                      <path
                        data-name="Path 22315"
                        d="M75.395,60.57,90.509,0S43.841,19.043,0,40.57Z"
                        transform="translate(819.622 85.112)"
                        fill="#001e42"
                      />
                      <path
                        data-name="Path 22316"
                        d="M111.017,0S79.506,55.727,63.539,87.428L0,85.57Z"
                        transform="translate(848.046 216.028)"
                        fill="#fca518"
                      />
                      <path
                        data-name="Path 22317"
                        d="M134.507,56.5,105.649,0,0,95.981Z"
                        transform="translate(704.629 109.213)"
                        fill="#ffe900"
                      />
                      <path
                        data-name="Path 22319"
                        d="M54.59,0,0,97.5s56.615-61.02,85.877-84.264Z"
                        transform="translate(757.141 303.669)"
                        fill="#df1f1c"
                      />
                      <path
                        data-name="Path 22320"
                        d="M207.448,62.756,238.019,0S96.46,59.028,0,113.644Z"
                        transform="translate(524.513 75.475)"
                        fill="#001976"
                      />
                      <path
                        data-name="Path 22321"
                        d="M165.854,0,0,55.042,125.57,72.266Z"
                        transform="translate(599.96 250.336)"
                        fill="#0092e5"
                      />
                      <path
                        data-name="Path 22322"
                        d="M221.8,0,159.824,110.255,0,57.453Z"
                        transform="translate(526.39 161.622) rotate(42)"
                        fill="#8ea7cd"
                      />
                      <path
                        data-name="Path 22323"
                        d="M96.217,0A1015.7,1015.7,0,0,0,0,74.638L221.8,17.223Z"
                        transform="translate(321.528 111.24)"
                        fill="#cbdae9"
                        opacity="0.6"
                      />
                      <path
                        data-name="Path 22324"
                        d="M95.148,0,0,78.612l255-25.74Z"
                        transform="translate(255.856 179.264)"
                        fill="#fca518"
                      />
                      <path
                        data-name="Path 22325"
                        d="M191.23,0S137.858,64.107,122.04,88.745L0,19.327Z"
                        transform="translate(430.216 529.436)"
                        fill="#ffe900"
                      />
                      <path
                        data-name="Path 22326"
                        d="M131.7,71.021,80.721,0,0,156.015S71.577,104.525,131.7,71.021Z"
                        transform="translate(444.134 573.244)"
                        fill="#0092e5"
                      />
                      <path
                        data-name="Path 22327"
                        d="M78.716,0,0,71.915l71.605,103.6Z"
                        transform="translate(241.88 404.207)"
                        fill="#fca518"
                      />
                      <path
                        data-name="Path 22328"
                        d="M146.3,40.908,76.194,0S22.747,48.851,0,72.3L82.463,99.989Z"
                        transform="translate(228.909 312.329)"
                        fill="#cbdae9"
                        opacity="0.6"
                      />
                      <path
                        data-name="Path 22329"
                        d="M177.586,0,0,51.3l191.921,73.819Z"
                        transform="translate(176.556 598.569)"
                        fill="#cbdae9"
                        opacity="0.6"
                      />
                      <path
                        data-name="Path 22330"
                        d="M133.854,59.3,79.7,0,0,95.081Z"
                        transform="translate(-49.169 423.907)"
                        fill="#ffe900"
                      />
                      <path
                        data-name="Path 22331"
                        d="M0,0,54.13,151.488S141.955,39.061,169.139,12.632Z"
                        transform="translate(102.504 85.516)"
                        fill="#8ea7cd"
                      />
                      <path
                        data-name="Path 22332"
                        d="M8.594,0,0,95.5l109.057,71.659Z"
                        transform="translate(151.193 625.114)"
                        fill="#0092e5"
                      />
                      <path
                        data-name="Path 22333"
                        d="M180.418,0,0,38.208l101.7,33.7Z"
                        transform="translate(43.967 298.01)"
                        fill="#df1f1c"
                      />
                      <ellipse
                        data-name="Ellipse 3085"
                        cx="71.924"
                        cy="71.924"
                        rx="71.924"
                        ry="71.924"
                        transform="translate(429.62 223.728)"
                        fill="#df1f1c"
                      />
                      <ellipse
                        data-name="Ellipse 3086"
                        cx="98.95"
                        cy="98.95"
                        rx="98.95"
                        ry="98.95"
                        transform="translate(-32.992 22.343)"
                        fill="#df1f1c"
                      />
                      <ellipse
                        data-name="Ellipse 3088"
                        cx="73.232"
                        cy="73.232"
                        rx="73.232"
                        ry="73.232"
                        transform="translate(311.526 423.07)"
                        fill="#001976"
                      />
                    </g>
                  </svg>
                </motion.div>
                <Container>
                  <Row>
                    <Col className="o-section-service__heading-col" width={1} widthSP={4}>
                      <motion.div
                        className="o-section-service__heading"
                        variants={serviceVariants.heading}
                        style={{ y: serviceParallax.heading }}
                      >
                        <Heading modifiers="dot">Services</Heading>
                      </motion.div>
                    </Col>
                    <Col className="o-section-service__text-col" width={5} widthSP={4}>
                      <motion.div
                        className="o-section-service__text"
                        variants={serviceVariants.content}
                        style={{ y: serviceParallax.content }}
                      >
                        <motion.h1 className="o-section-service__text-title" variants={serviceVariants.text}>
                          Beyond the Product Provider
                        </motion.h1>
                        <motion.p className="o-section-service__text-subtitle" variants={serviceVariants.text}>
                          Not just providing products, but offering extensive services throughout the entire process
                          from design to maintenance.
                        </motion.p>
                        <motion.div className="o-section-service__text-action" variants={serviceVariants.cta}>
                          <Button onClick={() => viewPage('/service-list')}>VIEW MORE</Button>
                        </motion.div>
                      </motion.div>
                    </Col>
                    <Col className="o-section-service__image-col" width={6} widthSP={4}>
                      <div className="o-section-service__image">
                        <motion.figure
                          className="o-section-service__image-item"
                          variants={serviceVariants.image}
                          onAnimationStart={() => setServicePictureReveal(false)}
                          onAnimationComplete={() => setServicePictureReveal(true)}
                          style={{ y: serviceParallax.image }}
                        >
                          <MaskImage
                            start={servicePictureReveal}
                            video={
                              <video muted={true} playsInline={true}>
                                <source type="video/webm" src="/videos/mask.webm" />
                                <source type="video/mp4" src="/videos/mask.mp4" />
                              </video>
                            }
                          >
                            <picture>
                              <source
                                type="image/webp"
                                media="(max-width: 768px)"
                                srcSet="/images/home/service-sp.webp, /images/home/service-sp@2x.webp 2x"
                              />
                              <source
                                type="image/webp"
                                media="(min-width: 769px)"
                                srcSet="/images/home/service.webp, /images/home/service@2x.webp 2x"
                              />
                              <source
                                type="image/jpg"
                                media="(max-width: 768px)"
                                srcSet="/images/home/service-sp.jpg, /images/home/service-sp@2x.jpg 2x"
                              />
                              <source
                                type="image/jpg"
                                media="(min-width: 769px)"
                                srcSet="/images/home/service.jpg, /images/home/service@2x.jpg 2x"
                              />
                              <img
                                src="/images/home/service.webp"
                                srcSet="/images/home/service.webp, /images/home/service@2x.webp 2x"
                                alt="Services"
                                width="375"
                                height="420"
                              />
                            </picture>
                          </MaskImage>
                        </motion.figure>
                        <motion.i
                          className="o-section-service__image-text"
                          variants={serviceVariants.imageText}
                          style={{ y: serviceParallax.imageText }}
                        >
                          S
                        </motion.i>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </motion.div>
            </section>
          )}
        </InView>
        {/* <!-- /SERVICES --> */}

        {/* <!-- PRODUCTS --> */}
        <InView threshold={0.1} triggerOnce>
          {({ inView, ref }) => (
            <motion.section
              ref={ref}
              className="o-section-product"
              initial={false}
              animate={inView ? 'enter' : 'exit'}
              variants={productVariants.root}
            >
              <motion.div className="o-section-product__graphic" variants={productVariants.graphic}>
                <svg data-name="Component 6 – 6" width="1004.643" height="1173.149" viewBox="0 0 1004.643 1173.149">
                  <g data-name="Group 25887" transform="translate(-8.856 920.6) rotate(-75)">
                    <path
                      data-name="Path 22315"
                      d="M75.395,60.57,90.509,0S43.841,19.043,0,40.57Z"
                      transform="translate(819.622 85.112)"
                      fill="#001e42"
                    />
                    <path
                      data-name="Path 22316"
                      d="M111.017,0S79.506,55.727,63.539,87.428L0,85.57Z"
                      transform="translate(848.046 216.028)"
                      fill="#fca518"
                    />
                    <path
                      data-name="Path 22317"
                      d="M134.507,56.5,105.649,0,0,95.981Z"
                      transform="translate(704.629 109.213)"
                      fill="#ffe900"
                    />
                    <path
                      data-name="Path 22319"
                      d="M54.59,0,0,97.5s56.615-61.02,85.877-84.264Z"
                      transform="translate(757.141 303.669)"
                      fill="#df1f1c"
                    />
                    <path
                      data-name="Path 22320"
                      d="M207.448,62.756,238.019,0S96.46,59.028,0,113.644Z"
                      transform="translate(524.513 75.475)"
                      fill="#001976"
                    />
                    <path
                      data-name="Path 22321"
                      d="M165.854,0,0,55.042,125.57,72.266Z"
                      transform="translate(599.96 250.336)"
                      fill="#0092e5"
                    />
                    <path
                      data-name="Path 22322"
                      d="M221.8,0,159.824,110.255,0,57.453Z"
                      transform="translate(526.39 161.622) rotate(42)"
                      fill="#8ea7cd"
                    />
                    <path
                      data-name="Path 22323"
                      d="M96.217,0A1015.7,1015.7,0,0,0,0,74.638L221.8,17.223Z"
                      transform="translate(321.528 111.24)"
                      fill="#cbdae9"
                      opacity="0.6"
                    />
                    <path
                      data-name="Path 22324"
                      d="M95.148,0,0,78.612l255-25.74Z"
                      transform="translate(255.856 179.264)"
                      fill="#fca518"
                    />
                    <path
                      data-name="Path 22325"
                      d="M191.23,0S137.858,64.107,122.04,88.745L0,19.327Z"
                      transform="translate(430.216 529.436)"
                      fill="#ffe900"
                    />
                    <path
                      data-name="Path 22326"
                      d="M131.7,71.021,80.721,0,0,156.015S71.577,104.525,131.7,71.021Z"
                      transform="translate(444.134 573.244)"
                      fill="#0092e5"
                    />
                    <path
                      data-name="Path 22327"
                      d="M78.716,0,0,71.915l71.605,103.6Z"
                      transform="translate(241.88 404.207)"
                      fill="#fca518"
                    />
                    <path
                      data-name="Path 22328"
                      d="M146.3,40.908,76.194,0S22.747,48.851,0,72.3L82.463,99.989Z"
                      transform="translate(228.909 312.329)"
                      fill="#cbdae9"
                      opacity="0.6"
                    />
                    <path
                      data-name="Path 22329"
                      d="M177.586,0,0,51.3l191.921,73.819Z"
                      transform="translate(176.556 598.569)"
                      fill="#cbdae9"
                      opacity="0.6"
                    />
                    <path
                      data-name="Path 22330"
                      d="M133.854,59.3,79.7,0,0,95.081Z"
                      transform="translate(-49.169 423.907)"
                      fill="#ffe900"
                    />
                    <path
                      data-name="Path 22331"
                      d="M0,0,54.13,151.488S141.955,39.061,169.139,12.632Z"
                      transform="translate(102.504 85.516)"
                      fill="#8ea7cd"
                    />
                    <path
                      data-name="Path 22332"
                      d="M8.594,0,0,95.5l109.057,71.659Z"
                      transform="translate(151.193 625.114)"
                      fill="#0092e5"
                    />
                    <path
                      data-name="Path 22333"
                      d="M180.418,0,0,38.208l101.7,33.7Z"
                      transform="translate(43.967 298.01)"
                      fill="#df1f1c"
                    />
                    <ellipse
                      data-name="Ellipse 3085"
                      cx="71.924"
                      cy="71.924"
                      rx="71.924"
                      ry="71.924"
                      transform="translate(429.62 223.728)"
                      fill="#df1f1c"
                    />
                    <ellipse
                      data-name="Ellipse 3086"
                      cx="98.95"
                      cy="98.95"
                      rx="98.95"
                      ry="98.95"
                      transform="translate(-32.992 22.343)"
                      fill="#df1f1c"
                    />
                    <ellipse
                      data-name="Ellipse 3088"
                      cx="73.232"
                      cy="73.232"
                      rx="73.232"
                      ry="73.232"
                      transform="translate(311.526 423.07)"
                      fill="#001976"
                    />
                  </g>
                </svg>
              </motion.div>
              <Container>
                <Row>
                  <Col className="o-section-product__image-col" width={6} widthSP={4}>
                    <div className="o-section-product__image">
                      <motion.figure
                        className="o-section-product__image-item"
                        variants={productVariants.image}
                        onAnimationStart={() => setProductPictureReveal(false)}
                        onAnimationComplete={() => setProductPictureReveal(true)}
                        style={{ y: productParallax.image }}
                      >
                        <MaskImage
                          start={productPictureReveal}
                          video={
                            <video muted={true} playsInline={true}>
                              <source type="video/webm" src="/videos/mask.webm" />
                              <source type="video/mp4" src="/videos/mask.mp4" />
                            </video>
                          }
                        >
                          <picture>
                            <source
                              type="image/webp"
                              media="(max-width: 768px)"
                              srcSet="/images/home/product-sp.webp, /images/home/product-sp@2x.webp 2x"
                            />
                            <source
                              type="image/webp"
                              media="(min-width: 769px)"
                              srcSet="/images/home/product.webp, /images/home/product@2x.webp 2x"
                            />
                            <source
                              type="image/jpg"
                              media="(max-width: 768px)"
                              srcSet="/images/home/product-sp.jpg, /images/home/product-sp@2x.jpg 2x"
                            />
                            <source
                              type="image/jpg"
                              media="(min-width: 769px)"
                              srcSet="/images/home/product.jpg, /images/home/product@2x.jpg 2x"
                            />
                            <img
                              src="/images/home/product.webp"
                              srcSet="/images/home/product.webp, /images/home/product@2x.webp 2x"
                              alt="Products"
                              width="375"
                              height="304"
                            />
                          </picture>
                        </MaskImage>
                      </motion.figure>
                      <motion.i
                        className="o-section-product__image-text"
                        variants={productVariants.imageText}
                        style={{ y: productParallax.imageText }}
                      >
                        P
                      </motion.i>
                    </div>
                  </Col>
                  <Col width={1} widthSP={4}>
                    &nbsp;
                  </Col>
                  <Col className="o-section-product__text-col" width={5} widthSP={4}>
                    <motion.div
                      className="o-section-product__heading"
                      variants={productVariants.heading}
                      style={{ y: productParallax.heading }}
                    >
                      <Heading modifiers="dot">Products</Heading>
                    </motion.div>
                    <motion.div
                      className="o-section-product__text"
                      variants={productVariants.content}
                      style={{ y: productParallax.content }}
                    >
                      <motion.h1 className="o-section-product__text-title" variants={productVariants.text}>
                        Perfect Match to Requirements
                      </motion.h1>
                      <motion.p className="o-section-product__text-subtitle" variants={productVariants.text}>
                        Combination of cutting edge products & finely tuned customization can be delivered flexibly &
                        expeditiously
                      </motion.p>
                      <motion.div className="o-section-product__text-action" variants={productVariants.cta}>
                        <Button onClick={() => viewPage('/products-list')}>VIEW MORE</Button>
                      </motion.div>
                    </motion.div>
                    <motion.div
                      className="o-section-product__features"
                      variants={productVariants.featureBlock}
                      style={{ y: productParallax.feature }}
                    >
                      <motion.div
                        className="o-section-product__features-title"
                        variants={productVariants.featureBlockTitle}
                      >
                        <Heading modifiers="line">Featured Products</Heading>
                      </motion.div>
                      <motion.ul className="o-section-product__features-list" variants={productVariants.feature}>
                        <motion.li className="o-section-product__features-item" variants={productVariants.featureItem}>
                          <Card
                            image="/images/logos/rtgs-p.svg"
                            imageSP="/images/logos/rtgs-sp.svg"
                            title="Real Time Gross Settlement (RTGS)"
                            onClick={() => viewPage('/products-list/rtgs')}
                          />
                        </motion.li>
                        <motion.li className="o-section-product__features-item" variants={productVariants.featureItem}>
                          <Card
                            image="/images/logos/rtp-p.svg"
                            imageSP="/images/logos/rtp-sp.svg"
                            title="Real-Time Payments System (RTP)"
                            onClick={() => viewPage('/products-list/rtp')}
                          />
                        </motion.li>
                        <motion.li className="o-section-product__features-item" variants={productVariants.featureItem}>
                          <Card
                            image="/images/logos/csd-top.svg"
                            imageSP="/images/logos/csd-top-sp.svg"
                            title={`Central Securities Depository<span class="a-br-sp"></span> (CSD)`}
                            onClick={() => viewPage('/products-list/csd')}
                          />
                        </motion.li>
                      </motion.ul>
                    </motion.div>
                  </Col>
                </Row>
              </Container>
            </motion.section>
          )}
        </InView>
        {/* <!-- /PRODUCTS --> */}

        {/* <!-- CASE STUDY --> */}
        <InView threshold={0.1} triggerOnce>
          {({ inView, ref }) => (
            <motion.section
              ref={ref}
              className="o-section-case-study"
              initial={false}
              animate={inView ? 'enter' : 'exit'}
              variants={caseStudyVariants.root}
            >
              <Container>
                <Row>
                  <Col className="o-section-case-study__heading-col" width={1} widthSP={4}>
                    <motion.div
                      className="o-section-case-study__heading"
                      variants={caseStudyVariants.heading}
                      style={{ y: caseStudyParallax.heading }}
                    >
                      <Heading modifiers="dot">Case Study</Heading>
                    </motion.div>
                  </Col>
                  <Col className="o-section-case-study__text-col" width={5} widthSP={4}>
                    <motion.div
                      className="o-section-case-study__text"
                      variants={caseStudyVariants.content}
                      style={{ y: caseStudyParallax.content }}
                    >
                      <motion.h1 className="o-section-case-study__text-title" variants={caseStudyVariants.text}>
                        Contributing
                        <br />
                        to Advancement
                      </motion.h1>
                      <motion.p className="o-section-case-study__text-subtitle" variants={caseStudyVariants.text}>
                        An ASEAN Country
                      </motion.p>
                      <motion.div className="o-section-case-study__text-action" variants={caseStudyVariants.cta}>
                        <Button onClick={() => viewPage('/case-study')}>VIEW MORE</Button>
                      </motion.div>
                    </motion.div>
                  </Col>
                  <Col className="o-section-case-study__image-col" width={6} widthSP={4}>
                    <div className="o-section-case-study__image">
                      <motion.figure
                        className="o-section-case-study__image-item"
                        variants={caseStudyVariants.image}
                        onAnimationStart={() => setCaseStudyPictureReveal(false)}
                        onAnimationComplete={() => setCaseStudyPictureReveal(true)}
                        style={{ y: caseStudyParallax.image }}
                      >
                        <MaskImage
                          start={caseStudyPictureReveal}
                          video={
                            <video muted={true} playsInline={true}>
                              <source type="video/webm" src="/videos/mask.webm" />
                              <source type="video/mp4" src="/videos/mask.mp4" />
                            </video>
                          }
                        >
                          <picture>
                            <source
                              type="image/jpg"
                              media="(max-width: 768px)"
                              srcSet="/images/home/case-study-sp.jpg, /images/home/case-study-sp@2x.jpg 2x"
                            />
                            <source
                              type="image/jpg"
                              media="(min-width: 769px)"
                              srcSet="/images/home/case-study.jpg, /images/home/case-study@2x.jpg 2x"
                            />
                            <source
                              type="image/webp"
                              media="(max-width: 768px)"
                              srcSet="/images/home/case-study-sp.webp, /images/home/case-study-sp@2x.webp 2x"
                            />
                            <source
                              type="image/webp"
                              media="(min-width: 769px)"
                              srcSet="/images/home/case-study.webp, /images/home/case-study@2x.webp 2x"
                            />
                            <img
                              src="/images/home/case-study.webp"
                              srcSet="/images/home/case-study.webp, /images/home/case-study@2x.webp 2x"
                              alt="Case Study"
                              width="375"
                              height="420"
                            />
                          </picture>
                        </MaskImage>
                      </motion.figure>
                      <motion.i
                        className="o-section-case-study__image-text"
                        variants={caseStudyVariants.imageText}
                        style={{ y: caseStudyParallax.imageText }}
                      >
                        C
                      </motion.i>
                    </div>
                  </Col>
                </Row>
              </Container>
            </motion.section>
          )}
        </InView>
        {/* <!-- /CASE STUDY --> */}
      </div>
    </Layout>
  );
};

export default withRouter(HomePage);
