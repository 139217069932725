import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { Icon } from 'components/atoms/icon';

export interface ButtonProps {
  children?: React.ReactNode;
  modifiers?: ModifierProp<'icon'>;
  label?: string;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({ children, modifiers, label, onClick }) => {
  let iconName = 'arrow-right-large';
  if (modifiers === 'icon') {
    iconName = 'arrow-right';
  }
  return (
    <button
      className={mapModifiers('a-button', modifiers)}
      onClick={onClick}
      aria-label={label || children?.toString()}
    >
      <div className="a-button__icon">
        <Icon name={iconName} color="white" />
      </div>
      <span className="a-button__text">{children}</span>
    </button>
  );
};
