import React from 'react';
import { mapModifiers, ModifierProp } from 'libs/component';
import { Button } from 'components/atoms/button';

export interface CardProps {
  image?: string;
  imageSP?: string;
  title?: string;
  description?: React.ReactNode;
  link?: string;
  modifiers?: ModifierProp<'large'>;
  onClick?: () => void;
}

export const Card: React.FC<CardProps> = ({ image, imageSP, title, description, modifiers, onClick }) => (
  <div className={mapModifiers('a-card', modifiers)} onClick={onClick}>
    <figure className="a-card__image">
      <picture>
        {imageSP && <source media="(max-width: 768px)" srcSet={imageSP} />}
        {image && imageSP && <source media="(min-width: 769px)" srcSet={image} />}
        <img src={image} width="120" height="120" alt={title} />
      </picture>
    </figure>
    <div className="a-card__content">
      <div
        className="a-card__title"
        dangerouslySetInnerHTML={{
          __html: title || '',
        }}
      />
      <div className="a-card__description">{description}</div>
    </div>
    <div className="a-card__action">
      <Button modifiers="icon" label={title} onClick={onClick} />
    </div>
  </div>
);
